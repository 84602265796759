import { companyDashboard, companyDashboardNew, companyDashboardAmount, companyDashboardPacks, companyPlans, ServiceproviderCompanyRates, GetListOfChecks, GetClientInformation, createCustomPlan, updateClientInformation, GetCandidateReportSummary, GetCandidateReportDetails, clientFeedbackSubmit, GetBillingDetails, DeletePlan, companyDashboardList, searchCandidate, getUserCheck, updateChecks } from "../actions/CompanyDetailsAction";
import { createSlice } from "@reduxjs/toolkit";


const companyDashboardSlice = createSlice({
  name: 'companyDashboard',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(companyDashboard.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(companyDashboard.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(companyDashboard.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const companyDashboardNewSlice = createSlice({
  name: 'companyDashboardNew',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(companyDashboardNew.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(companyDashboardNew.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(companyDashboardNew.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const companyDashboardAmountSlice = createSlice({
  name: 'companyDashboardAmount',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(companyDashboardAmount.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(companyDashboardAmount.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(companyDashboardAmount.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const companyDashboardPacksSlice = createSlice({
  name: 'companyDashboardPacks',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(companyDashboardPacks.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(companyDashboardPacks.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(companyDashboardPacks.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});



const companyPlanDetailsSlice = createSlice({
    name: 'companyPlans',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(companyPlans.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(companyPlans.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(companyPlans.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });



const ServiceproviderCompanyRatesSlice = createSlice({
    name: 'ServiceproviderCompanyRates',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ServiceproviderCompanyRates.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(ServiceproviderCompanyRates.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(ServiceproviderCompanyRates.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const GetListOfChecksSlice = createSlice({
    name: 'GetListOfChecks',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetListOfChecks.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(GetListOfChecks.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(GetListOfChecks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });

const GetClientInformationSlice = createSlice({
    name: 'GetClientInformation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetClientInformation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(GetClientInformation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(GetClientInformation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const createCustomPlanSlice = createSlice({
    name: 'createCustomPlan',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createCustomPlan.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(createCustomPlan.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(createCustomPlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const updateClientInformationSlice = createSlice({
    name: 'updateClientInformation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateClientInformation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(updateClientInformation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(updateClientInformation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const GetCandidateReportSummarySlice = createSlice({
    name: 'GetCandidateReportSummary',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetCandidateReportSummary.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(GetCandidateReportSummary.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(GetCandidateReportSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const GetCandidateReportDetailsSlice = createSlice({
    name: 'GetCandidateReportDetails',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetCandidateReportDetails.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(GetCandidateReportDetails.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(GetCandidateReportDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });



  const ClientFeedbackSubmitSlice = createSlice({
    name: 'clientFeedbackSubmit',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(clientFeedbackSubmit.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(clientFeedbackSubmit.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(clientFeedbackSubmit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });



  const GetBillingDetailsSlice = createSlice({
    name: 'GetBillingDetails',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetBillingDetails.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(GetBillingDetails.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(GetBillingDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });



  const DeletePlanSlice = createSlice({
    name: 'DeletePlan',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(DeletePlan.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(DeletePlan.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(DeletePlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });



  const companyDashboardListSlice = createSlice({
    name: 'companyDashboardList',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(companyDashboardList.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(companyDashboardList.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(companyDashboardList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });

  const searchCandidateSlice = createSlice({
    name: 'searchCandidate',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchCandidate.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(searchCandidate.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(searchCandidate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });


  const getUserCheckSlice = createSlice({
    name: 'getUserCheck',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserCheck.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(getUserCheck.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(getUserCheck.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });

  const updateChecksSlice = createSlice({
    name: 'updateChecks',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateChecks.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(updateChecks.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(updateChecks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
  });

export const reducers = {
  companyDashboard: companyDashboardSlice.reducer,
  companyDashboardNew: companyDashboardNewSlice.reducer,
  companyDashboardAmount: companyDashboardAmountSlice.reducer,
  companyDashboardPacks: companyDashboardPacksSlice.reducer,
  companyPlans: companyPlanDetailsSlice.reducer,
  ServiceproviderCompanyRates: ServiceproviderCompanyRatesSlice.reducer,
  GetListOfChecks: GetListOfChecksSlice.reducer,
  GetClientInformation: GetClientInformationSlice.reducer,
  createCustomPlan: createCustomPlanSlice.reducer,
  updateClientInformation: updateClientInformationSlice.reducer,
  GetCandidateReportSummary: GetCandidateReportSummarySlice.reducer,
  GetCandidateReportDetails: GetCandidateReportDetailsSlice.reducer,
  clientFeedbackSubmit: ClientFeedbackSubmitSlice.reducer,
  GetBillingDetails: GetBillingDetailsSlice.reducer,
  DeletePlan: DeletePlanSlice.reducer,
  companyDashboardList: companyDashboardListSlice.reducer,
  searchCandidate: searchCandidateSlice.reducer,
  getUserCheck: getUserCheckSlice.reducer,
  updateChecks: updateChecksSlice.reducer,
};
