import React, { useEffect, useState } from 'react'
import './AdminCvAnalysis.scss'
import EmptyDiscrepancyList from './EmptyDiscrepancyList';
import DiscrepancyList from './DiscrepancyList';
import DiscrepancyChat from './DiscrepancyChat';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCvAnalysisData } from '../../redux/actions/CvAnalysisAction';

const AdminCvAnalysis = () => {
  const dispatch = useDispatch()
  const [ShowResume, setShowResume] = useState(true);
  const [ShowDiscrepancyChat, setShowDiscrepancyChat] = useState(false);
  const [Discrepancies, setDiscrepancies] = useState([]);
  const [ChatDetails, setChatDetails] = useState(null);
  const [Trigger, setTrigger] = useState(false);
  const { username } = useParams();

  useEffect(() => {
    if(username) {
      fetchCvAnalysisData();
    }
  },[username, Trigger])


  useEffect(() => {
    const interval = setInterval(() => {
      setTrigger((prev) => !prev);
    }, 30000);

    return () => clearInterval(interval);
  }, []);


  const fetchCvAnalysisData = () => {
    const data = {
      candidate_username: username
    }

    const successFn = (response) => {
      setDiscrepancies(response?.data?.discrepancy_data);
    };

    const failureFn = (error) => {
      console.error("fetchCvAnalysisData failed", error);
    };

    dispatch(getCvAnalysisData({ data, successFn, failureFn }));
  };

  const handleChatClick = (chat) => {
    setShowDiscrepancyChat(true);
    setChatDetails(chat);
  }

  return (
    <>
      <main className='AdminCvAnalysis'>
        <section className={`DiscrepancySection ${ShowDiscrepancyChat ? 'HalfDiscrepancy' : ''}`}>
          <header>
            <h3>Discrepancy</h3>
          </header>
          {(Discrepancies && Discrepancies?.length > 0) ?
            <DiscrepancyList
              discrepancyData={Discrepancies}
              ShowChat={ShowDiscrepancyChat}
              handleChatClick={handleChatClick}
              Trigger={Trigger}
              setTrigger={setTrigger}
            />
            :
            <EmptyDiscrepancyList/>
          }
        </section>
        <section className={`ChatSectionWrapper ${ShowDiscrepancyChat ? 'ShowChat' : ''}`}>
          {ChatDetails && <DiscrepancyChat
            ShowResume={ShowResume}
            setShowResume={setShowResume}
            ShowDiscrepancyChat={ShowDiscrepancyChat}
            setShowDiscrepancyChat={setShowDiscrepancyChat}
            chatDetails={ChatDetails}
            Trigger={Trigger}
            setTrigger={setTrigger}
          />}
        </section>
      </main>
    </>
  )
}

export default AdminCvAnalysis