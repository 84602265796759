import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api";
import { showLoading } from "../slice/loadingSlice";

const ClientLoginAsyncThunk = (method, type, url, requiresToken = true, contentType = 'application/json') => createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;

    const headers = {
        'Content-Type': contentType
    };

    // Check if token is required and it exists in localStorage
    if (requiresToken && localStorage.getItem('token')) {
        headers['Authorization'] = 'Token ' + localStorage.getItem('token');
    }

    const apiRequest = {
        method: method,
        headers: headers,
        url: `${baseUrl}${url}`,
        data,
    };

    try {
        dispatch(showLoading.actions.toggleLoading(true));
        const res = await apiCall(apiRequest, dispatch);
        const response = res.data;

        dispatch(showLoading.actions.toggleLoading(false));

        if (response.statusFlag === true) {
            console.log(`login action ------ ${type}`, response);
            return response;
        } else {
            console.error(`account action - ${type}`, response);

            // Check if there is an error code in the response
            const errorCode = response.message;

            // You can throw a custom error with the error code
            throw new Error(errorCode);
        }
    } catch (error) {
        // dispatch(showLoading.actions.toggleLoading(false));
        throw error;
    }
});

// Define your actions
export const clientLicenseVerify = ClientLoginAsyncThunk('post', 'clientLicenseVerify', 'ClientUser/license_key/', false); 
export const clientCreatePassword = ClientLoginAsyncThunk('post', 'clientCreatePassword', 'ClientUser/add_client/', false); 
export const addClientInfo = ClientLoginAsyncThunk('post', 'addClientInfo', 'Client_information/add_client_info/'); 
export const addClientBusinessInfo = ClientLoginAsyncThunk('post', 'addClientBusinessInfo', 'Client_information/add_business_overview/'); 
export const addClientBusinessDetails = ClientLoginAsyncThunk('post', 'addClientBusinessDetails', 'Client_information/add_business_details/',true,'multipart/form-data'); 
export const beginVerification = ClientLoginAsyncThunk('post', 'beginVerification', 'CompanyCandidateRegister/candidate_begin_verification_old/',true,'multipart/form-data'); 
export const CancelbeginVerification = ClientLoginAsyncThunk('post', 'CancelbeginVerification', 'Client_billing_details/client_cancel_order_details/'); 
export const clientLogout = ClientLoginAsyncThunk('post', 'clientLogout', 'ClientUser/logout/'); 
