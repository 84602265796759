import { createSlice } from "@reduxjs/toolkit";
import { clientLicenseVerify, clientCreatePassword, addClientInfo, addClientBusinessInfo, addClientBusinessDetails, beginVerification, clientLogout, CancelbeginVerification} from "../actions/ClientLoginAction"

const clientLicenseVerifySlice = createSlice({
    name: 'clientLicenseVerify',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(clientLicenseVerify.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(clientLicenseVerify.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(clientLicenseVerify.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const clientCreatePasswordSlice = createSlice({
    name: 'clientCreatePassword',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(clientCreatePassword.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(clientCreatePassword.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(clientCreatePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const clientAddInfoSlice = createSlice({
    name: 'clientAddInfo',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addClientInfo.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(addClientInfo.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(addClientInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const clientAddBusinessInfoSlice = createSlice({
    name: 'addClientBusinessInfo',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addClientBusinessInfo.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(addClientBusinessInfo.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(addClientBusinessInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const clientAddBusinessDetailsSlice = createSlice({
    name: 'addClientBusinessDetails',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addClientBusinessDetails.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(addClientBusinessDetails.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(addClientBusinessDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const candidateBeginVerificationSlice = createSlice({
    name: 'beginVerification',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(beginVerification.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(beginVerification.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(beginVerification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const CancelbeginVerificationSlice = createSlice({
    name: 'CancelbeginVerification',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(CancelbeginVerification.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(CancelbeginVerification.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(CancelbeginVerification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const clientLogoutSlice = createSlice({
    name: 'clientLogout',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(clientLogout.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(clientLogout.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(clientLogout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});



export const reducers = {
    clientLicenseVerify: clientLicenseVerifySlice.reducer,
    clientCreatePassword: clientCreatePasswordSlice.reducer,
    clientAddInfo: clientAddInfoSlice.reducer,
    addClientBusinessInfo: clientAddBusinessInfoSlice.reducer,
    addClientBusinessDetails: clientAddBusinessDetailsSlice.reducer,
    beginVerification:  candidateBeginVerificationSlice.reducer,
    CancelbeginVerification:  CancelbeginVerificationSlice.reducer,
    clientLogout: clientLogoutSlice.reducer,
};