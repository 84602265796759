import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { CompanyUpdateFcmNotification } from "../redux/actions/CompanyNotificationAction";
import { useDispatch } from "react-redux";
// import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCl20p5awykNVgJyQc8DZhybNojA8Mkj2E",
  authDomain: "support-valiantt.firebaseapp.com",
  projectId: "support-valiantt",
  storageBucket: "support-valiantt.firebasestorage.app",
  messagingSenderId: "44422669415",
  appId: "1:44422669415:web:4db62096a28185a76e60c2",
  measurementId: "G-SDCZTS7MQ1"
};

// const dispatch = useDispatch()
// const YourComponent = () => {
//   const dispatch = useDispatch();
// const update_notification = (notification_id) => {
//   console.log("notify", notification_id)
//   const del_data = {
//     notification_id : notification_id
//   };
//   const successFn = (response) => {
//       alert(response.message)
//       // fetchNotificationData()

//   }
//   const failureFn = (error) =>{
//     alert(error)
//   }
//   dispatch(CompanyUpdateFcmNotification({data: del_data,successFn,failureFn}));
// }
// return null;
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)

// vapid_key = "BK4g3Puf2ojCqT3gt0jbNu1XlioriQxY_N86Tua-vAWh-na604RXjkuDG2_HJgAVvEQninsAbVQsed9W9i43LP8"

// granting permission to notification

export const generateToken = async () =>{
  const permission = await Notification.requestPermission();
  console.log(permission)
  if(permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: "BLntFaQHmxwmliUO-IUS5FTByLi1-JlgioNEJE351rKMaKZ06Zb3TMwjKHxVKF8q4FuTeSJAhPeEJ8jKhUQ599Y"      
    })
    console.log("token::::::::",token)
    localStorage.setItem("fcmToken",token)
  }  
  
}