import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api";
import { showLoading } from "../slice/loadingSlice";

const ClientLoginAsyncThunk = (method, type, url, requiresToken = true, contentType = 'application/json') => createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;

    const headers = {
        'Content-Type': contentType
    };

    // Check if token is required and it exists in localStorage
    if (requiresToken && localStorage.getItem('token')) {
        headers['Authorization'] = 'Token ' + localStorage.getItem('token');
    }

    const apiRequest = {
        method: method,
        headers: headers,
        url: `${baseUrl}${url}`,
        data,
    };

    try {
        dispatch(showLoading.actions.toggleLoading(true));
        const res = await apiCall(apiRequest, dispatch);
        const response = res.data;

        dispatch(showLoading.actions.toggleLoading(false));

        if (response.statusFlag === true) {
            console.log(`login action ------ ${type}`, response);
            return response;
        } else {
            console.error(`account action - ${type}`, response);

            // Check if there is an error code in the response
            const errorCode = response.message;

            // You can throw a custom error with the error code
            throw new Error(errorCode);
        }
    } catch (error) {
        // dispatch(showLoading.actions.toggleLoading(false));
        throw error;
    }
});

// Define your actions
export const ClientCandidateSearch = ClientLoginAsyncThunk('post', 'ClientCandidateSearch', 'master_dashboard/search_candidatemaster_details/');
export const GetStatsClient = ClientLoginAsyncThunk('post', 'GetStatsClient', 'master_dashboard/get_clientmaster_details/');
export const GetDailyStatsClient = ClientLoginAsyncThunk('post', 'GetDailyStatsClient', 'master_dashboard/get_clientmaster_checkcount_year/');
export const GetAmountSpentClient = ClientLoginAsyncThunk('post', 'GetAmountSpentClient', 'master_dashboard/get_clientmaster_checkcount/');
export const GetDashboardInteractionClient = ClientLoginAsyncThunk('post', 'GetDashboardInteractionClient', 'master_dashboard/get_master_candidate_details/');
export const GetPacksListClient = ClientLoginAsyncThunk('post', 'GetPacksListClient', 'master_dashboard/get_bgv_plan_details/');