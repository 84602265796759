import { createSlice } from "@reduxjs/toolkit";
import {
  addCompanyInsufficientEducationInfo,
  addCompanyInsufficientEmpInfo,
  adminInsufficientCandidateList,
  vendorAddInsufficientOtherCheck,
  vendorAddReferenceInsufficientChecks,
  vendorFetchInsufficientCheckwise,
} from "../actions/CompanyInsufficiencyAction";

const adminInsufficientCandidateListSlice = createSlice({
  name: "adminInsufficientCandidateList",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminInsufficientCandidateList.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(adminInsufficientCandidateList.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(adminInsufficientCandidateList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const vendorFetchInsufficientCheckwiseSlice = createSlice({
  name: "vendorFetchInsufficientCheckwise",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vendorFetchInsufficientCheckwise.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(vendorFetchInsufficientCheckwise.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(vendorFetchInsufficientCheckwise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const addCompanyInsufficientEducationInfoSlice = createSlice({
  name: "addCompanyInsufficientEducationInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyInsufficientEducationInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addCompanyInsufficientEducationInfo.fulfilled,
        (state, action) => {
          console.log("loaading");
          state.loading = false;
          state.data = action.payload;
          // Call the success callback if provided
          if (typeof action.meta.arg.successFn === "function") {
            action.meta.arg.successFn(action.payload);
            console.log("action page", action.payload);
          }
        }
      )
      .addCase(
        addCompanyInsufficientEducationInfo.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          // Call the failure callback if provided
          if (typeof action.meta.arg.failureFn === "function") {
            action.meta.arg.failureFn(action.error.message);
            console.log("action:::", action.error.message);
          }
        }
      );
  },
});

const addCompanyInsufficientEmpInfoSlice = createSlice({
  name: "addCompanyInsufficientEmpInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyInsufficientEmpInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(addCompanyInsufficientEmpInfo.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(addCompanyInsufficientEmpInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const vendorAddInsufficientOtherCheckSlice = createSlice({
  name: "vendorAddInsufficientOtherCheck",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vendorAddInsufficientOtherCheck.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(vendorAddInsufficientOtherCheck.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(vendorAddInsufficientOtherCheck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const vendorAddReferenceInsufficientChecksSlice = createSlice({
  name: "vendorAddReferenceInsufficientChecks",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vendorAddReferenceInsufficientChecks.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(
        vendorAddReferenceInsufficientChecks.fulfilled,
        (state, action) => {
          console.log("loaading");
          state.loading = false;
          state.data = action.payload;
          if (typeof action.meta.arg.successFn === "function") {
            action.meta.arg.successFn(action.payload);
            console.log("action page", action.payload);
          }
        }
      )
      .addCase(
        vendorAddReferenceInsufficientChecks.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          if (typeof action.meta.arg.failureFn === "function") {
            action.meta.arg.failureFn(action.error.message);
            console.log("action:::", action.error.message);
          }
        }
      );
  },
});

export const reducers = {
  adminInsufficientCandidateList: adminInsufficientCandidateListSlice.reducer,
  vendorFetchInsufficientCheckwise:
    vendorFetchInsufficientCheckwiseSlice.reducer,
  addCompanyInsufficientEducationInfo:
    addCompanyInsufficientEducationInfoSlice.reducer,
  addCompanyInsufficientEmpInfo: addCompanyInsufficientEmpInfoSlice.reducer,
  vendorAddInsufficientOtherCheck: vendorAddInsufficientOtherCheckSlice.reducer,
  vendorAddReferenceInsufficientChecks:
    vendorAddReferenceInsufficientChecksSlice.reducer,
};
