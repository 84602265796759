import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api/index";
import { showLoading } from "../slice/loadingSlice";

const createAccountAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("payload:::", payload);
    console.log("data:::", data);
    const apiRequest = {
      method: method,
      headers: {
        "Content-Type": contentType,
        Authorization: "Token " + localStorage.getItem("token"),
      },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const candidatePersonalInfo = createAccountAsyncThunk(
  "post",
  "candidatePersonalInfo",
  "multipart/form-data",
  "Candidate_information/add_candidate_personalinfo/"
);
export const candidateResidentInfo = createAccountAsyncThunk(
  "post",
  "candidateResidentInfo",
  "application/json",
  "Candidate_information/add_candidate_address/"
);
export const fetchAllCandidatedetails = createAccountAsyncThunk(
  "post",
  "fetchAllCandidatedetails",
  "application/json",
  "Candidate_information/fetch_candidate_info/"
);
export const addCommunicationPreference = createAccountAsyncThunk(
  "post",
  "addCommunicationPreference",
  "application/json",
  "Candidate_information/add_communication_preference/"
);

export const candidateLoginStatusUpdate = createAccountAsyncThunk(
  "post",
  "candidateLoginStatusUpdate",
  "application/json",
  "CompanyCandidateRegister/candidate_login_status_update/"
);
