import { ClientCandidateSearch, GetStatsClient, GetDailyStatsClient, GetAmountSpentClient, GetDashboardInteractionClient, GetPacksListClient } from "../actions/ClientDashboardAction";
import { createSlice } from "@reduxjs/toolkit";


const ClientCandidateSearchSlice = createSlice({
  name: 'ClientCandidateSearch',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(ClientCandidateSearch.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(ClientCandidateSearch.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(ClientCandidateSearch.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const GetStatsClientSlice = createSlice({
  name: 'GetStatsClient',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(GetStatsClient.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(GetStatsClient.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(GetStatsClient.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const GetDailyStatsClientSlice = createSlice({
  name: 'GetDailyStatsClient',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(GetDailyStatsClient.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(GetDailyStatsClient.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(GetDailyStatsClient.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const GetAmountSpentClientSlice = createSlice({
  name: 'GetAmountSpentClient',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(GetAmountSpentClient.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(GetAmountSpentClient.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(GetAmountSpentClient.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const GetDashboardInteractionClientSlice = createSlice({
  name: 'GetDashboardInteractionClient',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(GetDashboardInteractionClient.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(GetDashboardInteractionClient.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(GetDashboardInteractionClient.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


const GetPacksListClientSlice = createSlice({
  name: 'GetPacksListClient',
  initialState: {
      loading: false,
      data: null,
      error: null
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(GetPacksListClient.pending, (state) => {
              console.log("loaadingslice1")
              state.loading = true;
              state.error = null;
          })
          .addCase(GetPacksListClient.fulfilled, (state, action) => {
              console.log("loaading")
              state.loading = false;
              state.data = action.payload;
              // Call the success callback if provided
              if (typeof action.meta.arg.successFn === 'function') {
                  action.meta.arg.successFn(action.payload);
                  console.log("action page", action.payload)
              }
          })
          .addCase(GetPacksListClient.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
              // Call the failure callback if provided
              if (typeof action.meta.arg.failureFn === 'function') {
                  action.meta.arg.failureFn(action.error.message);
                  console.log("action:::", action.error.message)
              }
          });
  }
});


export const reducers = {
  ClientCandidateSearch: ClientCandidateSearchSlice.reducer,
  GetStatsClient: GetStatsClientSlice.reducer,
  GetDailyStatsClient: GetDailyStatsClientSlice.reducer,
  GetAmountSpentClient: GetAmountSpentClientSlice.reducer,
  GetDashboardInteractionClient: GetDashboardInteractionClientSlice.reducer,
  GetPacksListClient: GetPacksListClientSlice.reducer,
};