import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api/index";
import { showLoading } from "../slice/loadingSlice";

const createInsufficiencyAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const apiRequest = {
      method: method,
      headers: {
        "Content-Type": contentType,
        Authorization: "Token " + localStorage.getItem("token"),
      },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const adminInsufficientCandidateList = createInsufficiencyAsyncThunk(
  "post",
  "adminInsufficientCandidateList",
  "application/json",
  "insufficiencydetails/company_insufficient_candidate_list/"
);

export const vendorFetchInsufficientCheckwise = createInsufficiencyAsyncThunk(
  "post",
  "vendorFetchInsufficientCheckwise",
  "application/json",
  "insufficiencydetails/vendor_fetch_insufficient_check_wise/"
);

export const addCompanyInsufficientEducationInfo =
  createInsufficiencyAsyncThunk(
    "post",
    "addCompanyInsufficientEducationInfo",
    "multipart/form-data",
    "insufficiencydetails/add_company_insufficient_education_info/"
  );

export const addCompanyInsufficientEmpInfo = createInsufficiencyAsyncThunk(
  "post",
  "addCompanyInsufficientEmpInfo",
  "multipart/form-data",
  "insufficiencydetails/add_company_insufficient_emp_info/"
);

export const vendorAddInsufficientOtherCheck = createInsufficiencyAsyncThunk(
  "post",
  "vendorAddInsufficientOtherCheck",
  "multipart/form-data",
  "insufficiencydetails/vendor_add_insufficient_other_check/"
);

export const vendorAddReferenceInsufficientChecks =
  createInsufficiencyAsyncThunk(
    "post",
    "vendorAddInsufficientReferenceCheck",
    "application/json",
    "insufficiencydetails/vendor_add_reference_insufficient_checks/"
  );
