import { configureStore } from "@reduxjs/toolkit";
// import { reducers as vendorUserReducer } from "../redux/slices/LoginSignupSlice";
import { reducers as loginReducers } from "../redux/slices/LoginSlice";
import { reducers as companyRegisterReducers } from "../redux/slices/CompanyRegisterationSlice";
import { reducers as companyBeginVerificationReducers } from "../redux/slices/CompanyBeginVerificationSlice";
import { reducers as companyDashboardReducers } from "../redux/slices/CompanyDashboardSlice";
import { reducers as companyPackReducers } from "../redux/slices/CompanyPackDetailsSlice";
import { reducers as companyAccountSettingReducers } from "../redux/slices/CompanyAccountSettingSlice";
import { reducers as companyInsufficiencyReducers } from "../redux/slices/CompanyInsufficiencySlice";
import { reducers as companyAdditonalCostReducers } from "../redux/slices/CompanyAdditionalCostSlice";
import { reducers as fetchCandidateStatusDetailsReducer } from "../redux/slices/VendorCandidateDetailsSlice";
import { reducers as UserManagementReducer } from "../redux/slices/UserManagementSlice";
import { reducers as BillingDetailsReducer } from "../redux/slices/CompanyBillingDetailsSlice";
import { reducers as NotificationReducer } from "../redux/slices/CompanyNotificationSlice";
import { reducers as ClientReducers } from "../redux/slices/ClientSlice";
import { reducers as ReportsReducer } from "../redux/slices/ReporstSlice";
import { reducers as CvAnalysisReducer } from "../redux/slices/CvAnalysisSlice";

const store = configureStore({
  reducer: {
    // vendorUserList: vendorUserReducer.vendorUserList,
    userLogin: loginReducers.userLogin,
    userLogout: loginReducers.userLogout,
    licenseVerification: loginReducers.licenseVerification,
    setPassword: loginReducers.setPassword,
    forgotPassword: loginReducers.forgotPassword,
    setNewPassword: loginReducers.setNewPassword,
    getClientAccessControl: loginReducers.getClientAccessControl,
    addCompanyInformation: companyRegisterReducers.addCompanyInformation,
    addCompanyOverview: companyRegisterReducers.addCompanyOverview,
    addCompanyDetails: companyRegisterReducers.addCompanyDetails,
    getPlan: companyBeginVerificationReducers.getPlan,
    candidateBeginVerificationOld:
      companyBeginVerificationReducers.candidateBeginVerificationOld,
    candidateBeginVerificationUpdateOld:
      companyBeginVerificationReducers.candidateBeginVerificationUpdateOld,
    clientCancelOrderDetails:
      companyBeginVerificationReducers.clientCancelOrderDetails,
    updateServiceProviderChecks:
      companyBeginVerificationReducers.updateServiceProviderChecks,
    fetchCandidateDetails: companyDashboardReducers.fetchCandidateDetails,
    getApiClient: companyDashboardReducers.getApiClient,
    overallTatPercentage: companyDashboardReducers.overallTatPercentage,
    candidateStatuscountAmountSpent:
      companyDashboardReducers.candidateStatuscountAmountSpent,
    candidateChecksCount: companyDashboardReducers.candidateChecksCount,
    getMonthlyBgvCount: companyDashboardReducers.getMonthlyBgvCount,
    getBgvCount: companyDashboardReducers.getBgvCount,
    getCheckAverageDetails: companyDashboardReducers.getCheckAverageDetails,
    CheckInteractivity: companyDashboardReducers.CheckInteractivity,
    DeletePlan: companyPackReducers.DeletePlan,
    companyPlans: companyPackReducers.companyPlans,
    createCustomPlan: companyPackReducers.createCustomPlan,
    GetClientInformation: companyAccountSettingReducers.GetClientInformation,
    updateClientInformation:
      companyAccountSettingReducers.updateClientInformation,
    clientFeedbackSubmit: companyAccountSettingReducers.clientFeedbackSubmit,
    addCompanyInsufficientEducationInfo:
      companyInsufficiencyReducers.addCompanyInsufficientEducationInfo,
    addCompanyInsufficientEmpInfo:
      companyInsufficiencyReducers.addCompanyInsufficientEmpInfo,
    adminInsufficientCandidateList:
      companyInsufficiencyReducers.adminInsufficientCandidateList,
    vendorAddInsufficientOtherCheck:
      companyInsufficiencyReducers.vendorAddInsufficientOtherCheck,
    vendorAddReferenceInsufficientChecks:
      companyInsufficiencyReducers.vendorAddReferenceInsufficientChecks,
    vendorFetchInsufficientCheckwise:
      companyInsufficiencyReducers.vendorFetchInsufficientCheckwise,
    listAdditionalCostVendor:
      companyAdditonalCostReducers.listAdditionalCostVendor,
    updateAdditionalCostApproval:
      companyAdditonalCostReducers.updateAdditionalCostApproval,
    fetchCandidateStatusDetailsNew:
      fetchCandidateStatusDetailsReducer.fetchCandidateStatusDetailsNew,
    createCustomtablesInfo:
      fetchCandidateStatusDetailsReducer.createCustomtablesInfo,
    vendorUserList: UserManagementReducer.vendorUserList,
    registerVendorUser: UserManagementReducer.registerVendorUser,
    viewVendorUser: UserManagementReducer.viewVendorUser,
    updateVendorUser: UserManagementReducer.updateVendorUser,
    deactivateVendorUser: UserManagementReducer.deactivateVendorUser,
    accessPagesList: UserManagementReducer.accessPagesList,
    subuserSetPassword: UserManagementReducer.subuserSetPassword,
    clientInvoiceTransactionList:
      BillingDetailsReducer.clientInvoiceTransactionList,
    fetchCandidateTransactionDetailsSlice:
      BillingDetailsReducer.fetchCandidateTransactionDetails,
    adminCandidateReportSummary:
      fetchCandidateStatusDetailsReducer.adminCandidateReportSummary,
    adminCandidateReportDetails:
      fetchCandidateStatusDetailsReducer.adminCandidateReportDetails,
    jobseekerIdChangeRequest: fetchCandidateStatusDetailsReducer.jobseekerIdChangeRequest,
    clientPaymentLinkGenerate:
      companyBeginVerificationReducers.clientPaymentLinkGenerate,
    getClientSearchHistory: companyDashboardReducers.getClientSearchHistory,
    CompanyNotificationList: NotificationReducer.CompanyNotificationList,
    CompanyReadByUser: NotificationReducer.CompanyReadByUser,
    CompanyReadAllMessage: NotificationReducer.CompanyReadAllMessage,
    CompanyDeleteAllMessage: NotificationReducer.CompanyDeleteAllMessage,
    CompanyDeleteNotification: NotificationReducer.CompanyDeleteNotification,
    fetchExistingUser: companyBeginVerificationReducers.fetchExistingUser,
    fetchOrderDetails: companyBeginVerificationReducers.fetchOrderDetails,
    GetPacksListClient: ClientReducers.GetPacksListClient,
    GetStatsClient: ClientReducers.GetStatsClient,
    GetDashboardInteractionClient: ClientReducers.GetDashboardInteractionClient,
    ClientCandidateSearch: ClientReducers.ClientCandidateSearch,
    FetchCandidateReportsFields: ReportsReducer.FetchCandidateReportsFields,
    getOverallCheckReportResponse: ReportsReducer.getOverallCheckReportResponse,
    generateCandidateCheckReportXlsx: ReportsReducer.generateCandidateCheckReportXlsx,
    getCvAnalysisData: CvAnalysisReducer.getCvAnalysisData,
    addCvDiscrepancy: CvAnalysisReducer.addCvDiscrepancy,
    sendMessage: CvAnalysisReducer.sendMessage,
  },
});

export default store;
