import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api/index";
import { showLoading } from "../slice/loadingSlice"


const createAccountAsyncThunk = (method, type, contentType, url) => createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data)
    const apiRequest = {
        method: method,
        headers: { 'Content-Type': contentType, Authorization: 'Token ' + localStorage.getItem('token') },
        url: `${baseUrl}${url}`,
        data,
    };

    try {
        dispatch(showLoading.actions.toggleLoading(true));
        const res = await apiCall(apiRequest, dispatch);
        const response = res.data;

        dispatch(showLoading.actions.toggleLoading(false));

        if (response.statusFlag === true) {
            console.log(`login action ------ ${type}`, response);
            return response;
        } else {
            console.error(`account action - ${type}`, response);

            // Check if there is an error code in the response
            const errorCode = response.message;

            // You can throw a custom error with the error code
            throw new Error(errorCode);
        }
    } catch (error) {
        dispatch(showLoading.actions.toggleLoading(false));
        throw error;
    }
});



export const companyDashboard = createAccountAsyncThunk('post', 'companyDashboard', 'application/json', 'CompanyDashboard/fetch_candidate_status/');
export const companyDashboardNew = createAccountAsyncThunk('post', 'companyDashboardNew', 'application/json', 'CompanyDashboard/fetch_candidate_statuscount_amountspent/');
export const companyDashboardAmount = createAccountAsyncThunk('post', 'companyDashboardAmount', 'application/json', 'CompanyDashboard/get_mothly_bgv_count/');
export const companyDashboardPacks = createAccountAsyncThunk('post', 'companyDashboardPacks', 'application/json', 'CompanyDashboard/get_bgv_count/');
export const companyPlans = createAccountAsyncThunk('post', 'companyPlanDetails', 'application/json', 'ClientPlanDetails/get_plan/');
export const ServiceproviderCompanyRates = createAccountAsyncThunk('post', 'ServiceproviderCompanyRates', 'application/json', 'ClientPlanDetails/client_service_provider_rates/');
export const GetListOfChecks = createAccountAsyncThunk('POST', 'GetListOfChecks', 'application/json', 'CompanyCandidateRegister/get_api_client/');
export const GetClientInformation = createAccountAsyncThunk('POST', 'GetClientInformation', 'application/json', 'Client_information/fetch_clientinfo_details/');
export const createCustomPlan = createAccountAsyncThunk('POST', 'createCustomPlan', 'application/json', 'ClientPlanDetails/create_plan/');
export const updateClientInformation = createAccountAsyncThunk('PUT', 'updateClientInformation', 'multipart/form-data', 'Client_information/update_clientinfo_details/');
export const GetCandidateReportSummary = createAccountAsyncThunk('POST', 'GetCandidateReportSummary', 'application/json', 'Candidate_report/candidate_report_summary/');
export const GetCandidateReportDetails = createAccountAsyncThunk('POST', 'GetCandidateReportDetails', 'application/json', 'Candidate_report/candidate_report_details/');
export const clientFeedbackSubmit = createAccountAsyncThunk('POST', 'clientFeedbackSubmit', 'application/json', 'ClientFeedBack/client_feed_back/');
// export const GetBillingDetails = createAccountAsyncThunk('POST', 'GetBillingDetails', 'application/json', 'Client_billing_details/client_order_details/');
export const GetBillingDetails = createAccountAsyncThunk('POST', 'GetBillingDetails', 'application/json', 'Client_billing_details/client_monthly_invoice/');
export const DeletePlan = createAccountAsyncThunk('DELETE', 'DeletePlan', 'application/json', 'ClientPlanDetails/delete_plan/');
export const companyDashboardList = createAccountAsyncThunk('post', 'companyDashboardList', 'application/json', 'CompanyDashboard/fetch_candidate_status_details_new/');
export const searchCandidate = createAccountAsyncThunk('post', 'searchCandidate', 'application/json', 'CompanyDashboard/search_candidate/');
export const getUserCheck = createAccountAsyncThunk('post', 'getUserCheck', 'application/json', 'CompanyCandidateRegister/get_candidate_check_data/');
export const updateChecks = createAccountAsyncThunk('post', 'updateChecks', 'application/json', 'CompanyCandidateRegister/update_serviceprovider_checks/');
