import React from 'react'

const EmptyDiscrepancyList = () => {
  return (
    <div className='EmptyDiscrepancyList'>
      <svg xmlns="http://www.w3.org/2000/svg" width="8rem" height="6rem" viewBox="0 0 181 141" fill="none">
        <g opacity="0.3">
          <path d="M170.825 0.742207C170.575 0.742207 170.325 0.742207 170.065 0.742207H10.9645C8.23453 0.736894 5.61408 1.81561 3.679 3.74132C1.74391 5.66704 0.652478 8.28221 0.644531 11.0122V129.952C0.644163 132.655 1.70597 135.249 3.60098 137.176C5.49599 139.103 8.07237 140.208 10.7745 140.252H170.065C172.375 140.249 174.617 139.469 176.43 138.038C178.244 136.607 179.524 134.608 180.065 132.362C180.253 131.573 180.35 130.764 180.355 129.952V11.0122C180.352 8.41465 179.369 5.91381 177.602 4.00975C175.835 2.10568 173.415 0.938723 170.825 0.742207Z" fill="#AAB6C2"/>
          <path d="M163.934 6.77344H17.0736C12.0257 6.77344 7.93359 10.8656 7.93359 15.9134V125.063C7.93359 130.111 12.0257 134.203 17.0736 134.203H163.934C168.981 134.203 173.074 130.111 173.074 125.063V15.9134C173.074 10.8656 168.981 6.77344 163.934 6.77344Z" fill="white"/>
          <path d="M163.945 6.52352H17.0745C15.8389 6.51826 14.6144 6.75692 13.4712 7.22583C12.3279 7.69474 11.2885 8.38467 10.4125 9.25608C9.53642 10.1275 8.84097 11.1632 8.36599 12.3039C7.89101 13.4446 7.64584 14.6679 7.64453 15.9035V125.064C7.64584 126.3 7.89094 127.523 8.3658 128.665C8.84065 129.806 9.53595 130.842 10.4119 131.714C11.2878 132.587 12.3271 133.278 13.4704 133.748C14.6137 134.218 15.8384 134.457 17.0745 134.454H163.945C166.435 134.454 168.823 133.464 170.584 131.703C172.345 129.942 173.335 127.554 173.335 125.064V15.9035C173.335 14.6709 173.092 13.4503 172.62 12.3116C172.148 11.173 171.456 10.1385 170.584 9.26732C169.712 8.39618 168.676 7.70546 167.537 7.23466C166.398 6.76386 165.177 6.52221 163.945 6.52352ZM172.835 125.064C172.832 127.42 171.894 129.68 170.228 131.347C168.561 133.013 166.301 133.951 163.945 133.954H17.0745C14.7176 133.951 12.4579 133.013 10.7913 131.347C9.12465 129.68 8.18718 127.42 8.18453 125.064V15.9035C8.18718 13.5475 9.12497 11.2888 10.7919 9.62381C12.4588 7.95877 14.7185 7.02352 17.0745 7.02352H163.945C166.301 7.02352 168.56 7.95877 170.227 9.62381C171.894 11.2888 172.832 13.5475 172.835 15.9035V125.064Z" fill="black"/>
          <path d="M61.9655 16H57.3555V16.92H61.9655V16Z" fill="#9CCCEE"/>
          <path d="M54.8541 70.6641H50.2441V71.5841H54.8541V70.6641Z" fill="#9CCCEE"/>
          <path d="M43.5045 16H38.8945V16.92H43.5045V16Z" fill="#9CCCEE"/>
          <path d="M52.735 16H48.125V16.92H52.735V16Z" fill="#9CCCEE"/>
          <path d="M45.6237 70.6641H41.0137V71.5841H45.6237V70.6641Z" fill="#9CCCEE"/>
          <path d="M64.0846 70.6641H59.4746V71.5841H64.0846V70.6641Z" fill="#9CCCEE"/>
          <path d="M89.6354 16H85.0254V16.92H89.6354V16Z" fill="#9CCCEE"/>
          <path d="M91.7545 70.6641H87.1445V71.5841H91.7545V70.6641Z" fill="#9CCCEE"/>
          <path d="M71.1842 16H66.5742V16.92H71.1842V16Z" fill="#9CCCEE"/>
          <path d="M73.3053 70.6641H68.6953V71.5841H73.3053V70.6641Z" fill="#9CCCEE"/>
          <path d="M80.4147 16H75.8047V16.92H80.4147V16Z" fill="#9CCCEE"/>
          <path d="M82.5338 70.6641H77.9238V71.5841H82.5338V70.6641Z" fill="#9CCCEE"/>
          <path d="M17.0641 53.8594H15.9141V57.5494H17.0641V53.8594Z" fill="#9CCCEE"/>
          <path d="M17.0641 46.4844H15.9141V50.1744H17.0641V46.4844Z" fill="#9CCCEE"/>
          <path d="M17.0641 39.1094H15.9141V42.7994H17.0641V39.1094Z" fill="#9CCCEE"/>
          <path d="M17.0641 16.9844H15.9141V20.6744H17.0641V16.9844Z" fill="#9CCCEE"/>
          <path d="M17.0641 31.7344H15.9141V35.4244H17.0641V31.7344Z" fill="#9CCCEE"/>
          <path d="M25.0553 16H20.4453V16.92H25.0553V16Z" fill="#9CCCEE"/>
          <path d="M27.1745 70.6641H22.5645V71.5841H27.1745V70.6641Z" fill="#9CCCEE"/>
          <path d="M34.2838 16H29.6738V16.92H34.2838V16Z" fill="#9CCCEE"/>
          <path d="M36.4049 70.6641H31.7949V71.5841H36.4049V70.6641Z" fill="#9CCCEE"/>
          <path d="M17.0641 61.2344H15.9141V64.9244H17.0641V61.2344Z" fill="#9CCCEE"/>
          <path d="M17.0641 68.6094H15.9141V71.5894H17.9541V70.6694H17.0641V68.6094Z" fill="#9CCCEE"/>
          <path d="M17.0641 24.3594H15.9141V28.0494H17.0641V24.3594Z" fill="#9CCCEE"/>
          <path d="M166.144 55.3359H164.994V59.0259H166.144V55.3359Z" fill="#9CCCEE"/>
          <path d="M164.985 70.6759H160.955V71.5959H166.145V70.0859H164.985V70.6759Z" fill="#9CCCEE"/>
          <path d="M166.144 62.7031H164.994V66.3931H166.144V62.7031Z" fill="#9CCCEE"/>
          <path d="M156.335 70.6641H151.725V71.5841H156.335V70.6641Z" fill="#9CCCEE"/>
          <path d="M98.8639 16H94.2539V16.92H98.8639V16Z" fill="#9CCCEE"/>
          <path d="M166.144 47.9531H164.994V51.6431H166.144V47.9531Z" fill="#9CCCEE"/>
          <path d="M154.215 16H149.605V16.92H154.215V16Z" fill="#9CCCEE"/>
          <path d="M166.144 18.4531H164.994V22.1431H166.144V18.4531Z" fill="#9CCCEE"/>
          <path d="M166.144 25.8359H164.994V29.5259H166.144V25.8359Z" fill="#9CCCEE"/>
          <path d="M166.144 33.2031H164.994V36.8931H166.144V33.2031Z" fill="#9CCCEE"/>
          <path d="M166.144 40.5859H164.994V44.2759H166.144V40.5859Z" fill="#9CCCEE"/>
          <path d="M163.444 16H158.834V16.92H163.444V16Z" fill="#9CCCEE"/>
          <path d="M119.434 70.6641H114.824V71.5841H119.434V70.6641Z" fill="#9CCCEE"/>
          <path d="M117.315 16H112.705V16.92H117.315V16Z" fill="#9CCCEE"/>
          <path d="M110.204 70.6641H105.594V71.5841H110.204V70.6641Z" fill="#9CCCEE"/>
          <path d="M100.985 70.6641H96.375V71.5841H100.985V70.6641Z" fill="#9CCCEE"/>
          <path d="M108.085 16H103.475V16.92H108.085V16Z" fill="#9CCCEE"/>
          <path d="M135.764 16H131.154V16.92H135.764V16Z" fill="#9CCCEE"/>
          <path d="M137.885 70.6641H133.275V71.5841H137.885V70.6641Z" fill="#9CCCEE"/>
          <path d="M144.995 16H140.385V16.92H144.995V16Z" fill="#9CCCEE"/>
          <path d="M147.114 70.6641H142.504V71.5841H147.114V70.6641Z" fill="#9CCCEE"/>
          <path d="M128.665 70.6641H124.055V71.5841H128.665V70.6641Z" fill="#9CCCEE"/>
          <path d="M126.544 16H121.934V16.92H126.544V16Z" fill="#9CCCEE"/>
          <path d="M154.224 24.1562H76.5742V35.5663H154.224V24.1562Z" fill="#AAB6C2"/>
          <path d="M91.1042 42.9219H76.5742V44.6619H91.1042V42.9219Z" fill="#AAB6C2"/>
          <path d="M154.225 42.9219H96.2852V44.6619H154.225V42.9219Z" fill="#AAB6C2"/>
          <path d="M114.894 47.0469H76.5742V48.7869H114.894V47.0469Z" fill="#AAB6C2"/>
          <path d="M132.514 47.0469H117.984V48.7869H132.514V47.0469Z" fill="#AAB6C2"/>
          <path d="M154.225 47.0469H136.125V48.7869H154.225V47.0469Z" fill="#AAB6C2"/>
          <path d="M122.404 51.1562H76.5742V52.8963H122.404V51.1562Z" fill="#AAB6C2"/>
          <path d="M154.225 51.1562H126.555V52.8963H154.225V51.1562Z" fill="#AAB6C2"/>
          <path d="M154.224 55.3594H117.984V57.0994H154.224V55.3594Z" fill="#AAB6C2"/>
          <path d="M85.5242 55.2734H76.5742V57.0134H85.5242V55.2734Z" fill="#AAB6C2"/>
          <path d="M114.324 55.2734H88.8438V57.0134H114.324V55.2734Z" fill="#AAB6C2"/>
          <path d="M91.1042 59.3828H76.5742V61.1228H91.1042V59.3828Z" fill="#AAB6C2"/>
          <path d="M154.225 59.3828H96.2852V61.1228H154.225V59.3828Z" fill="#AAB6C2"/>
          <path d="M15.8894 114.257L15.1094 114.258L15.1132 117.378L15.8932 117.377L15.8894 114.257Z" fill="#9CCCEE"/>
          <path d="M15.0645 86.1659H15.8445C15.8765 85.2144 16.274 84.3118 16.9545 83.6459L16.4145 83.0859C15.5825 83.899 15.0985 85.0032 15.0645 86.1659Z" fill="#9CCCEE"/>
          <path d="M15.864 101.781H15.084L15.094 104.901H15.864V101.781Z" fill="#9CCCEE"/>
          <path d="M15.8755 108.021L15.1055 108.023L15.1136 111.143L15.8836 111.141L15.8755 108.021Z" fill="#9CCCEE"/>
          <path d="M15.8601 95.5372L15.0801 95.5391L15.0877 98.6591L15.8677 98.6572L15.8601 95.5372Z" fill="#9CCCEE"/>
          <path d="M15.8952 120.516H15.1152V122.366C15.1175 122.828 15.1917 123.286 15.3352 123.726L16.0752 123.486C15.9556 123.124 15.8948 122.746 15.8952 122.366V120.516Z" fill="#9CCCEE"/>
          <path d="M62.5886 116.217L61.8086 116.219L61.8168 119.339L62.5968 119.337L62.5886 116.217Z" fill="#9CCCEE"/>
          <path d="M62.5652 109.991L61.7852 109.992L61.789 113.112L62.569 113.111L62.5652 109.991Z" fill="#9CCCEE"/>
          <path d="M61.7754 100.622L62.5554 100.612L62.5454 97.4922L61.7754 97.5022V100.622Z" fill="#9CCCEE"/>
          <path d="M19.6441 126.062C19.0261 126.06 18.418 125.905 17.8741 125.612L17.4941 126.292C18.1543 126.649 18.8936 126.835 19.6441 126.832H20.6441V126.062H19.6441ZM61.7641 88.1319H62.5341V86.2619C62.5351 85.8069 62.4643 85.3547 62.3241 84.9219L61.5841 85.1519C61.6958 85.5113 61.7531 85.8855 61.7541 86.2619L61.7641 88.1319Z" fill="#9CCCEE"/>
          <path d="M15.8386 89.3037L15.0586 89.3047L15.0624 92.4247L15.8424 92.4237L15.8386 89.3037Z" fill="#9CCCEE"/>
          <path d="M62.5417 91.248L61.7617 91.25L61.7699 94.37L62.5499 94.3679L62.5417 91.248Z" fill="#9CCCEE"/>
          <path d="M61.7852 106.864H62.5652L62.5552 103.734L61.7852 103.744V106.864Z" fill="#9CCCEE"/>
          <path d="M19.4941 81.8459V82.2259L19.5441 82.6159H22.6641V81.8359L19.4941 81.8459Z" fill="#9CCCEE"/>
          <path d="M51.8837 126.01L48.7637 126.016L48.7652 126.796L51.8852 126.79L51.8837 126.01Z" fill="#9CCCEE"/>
          <path d="M45.6434 126.025L42.5234 126.031L42.5249 126.811L45.6449 126.805L45.6434 126.025Z" fill="#9CCCEE"/>
          <path d="M47.6161 81.7992L44.4961 81.8047L44.4975 82.5847L47.6175 82.5792L47.6161 81.7992Z" fill="#9CCCEE"/>
          <path d="M55.0039 126.01V126.78H58.1239V126.39L58.1039 126L55.0039 126.01Z" fill="#9CCCEE"/>
          <path d="M60.6441 124.961L61.1841 125.521C62.0024 124.73 62.4916 123.658 62.5541 122.521H61.7741C61.7158 123.447 61.3127 124.318 60.6441 124.961ZM56.9941 81.7813V82.5613H58.0441C58.6706 82.5571 59.2873 82.7156 59.8341 83.0213L60.2141 82.3413C59.5506 81.9721 58.8035 81.7793 58.0441 81.7813H56.9941Z" fill="#9CCCEE"/>
          <path d="M53.8661 81.7992L50.7461 81.8047L50.7475 82.5847L53.8675 82.5792L53.8661 81.7992Z" fill="#9CCCEE"/>
          <path d="M28.8973 81.8305L25.7773 81.8359L25.7787 82.6159L28.8987 82.6105L28.8973 81.8305Z" fill="#9CCCEE"/>
          <path d="M41.3837 81.8027L38.2637 81.8125L38.2661 82.5925L41.3861 82.5827L41.3837 81.8027Z" fill="#9CCCEE"/>
          <path d="M33.163 126.033L30.043 126.039L30.0445 126.819L33.1645 126.813L33.163 126.033Z" fill="#9CCCEE"/>
          <path d="M26.9247 126.049L23.8047 126.055L23.8062 126.835L26.9262 126.829L26.9247 126.049Z" fill="#9CCCEE"/>
          <path d="M35.1376 81.8227L32.0176 81.8281L32.0189 82.6081L35.1389 82.6027L35.1376 81.8227Z" fill="#9CCCEE"/>
          <path d="M39.4032 126.033L36.2832 126.039L36.2847 126.809L39.4047 126.803L39.4032 126.033Z" fill="#9CCCEE"/>
          <path d="M67.8659 108.014L67.0859 108.016L67.0941 111.136L67.8741 111.134L67.8659 108.014Z" fill="#9CCCEE"/>
          <path d="M67.8542 101.779L67.0742 101.781L67.0818 104.901L67.8618 104.899L67.8542 101.779Z" fill="#9CCCEE"/>
          <path d="M67.8386 95.545L67.0586 95.5469L67.0662 98.6669L67.8462 98.665L67.8386 95.545Z" fill="#9CCCEE"/>
          <path d="M67.8796 114.249L67.0996 114.25L67.1034 117.37L67.8834 117.369L67.8796 114.249Z" fill="#9CCCEE"/>
          <path d="M67.0449 86.1659H67.8249C67.8569 85.2144 68.2545 84.3118 68.9349 83.6459L68.3949 83.0859C67.5673 83.9019 67.0841 85.0043 67.0449 86.1659Z" fill="#9CCCEE"/>
          <path d="M67.8191 89.3115L67.0391 89.3125L67.0429 92.4325L67.8229 92.4315L67.8191 89.3115Z" fill="#9CCCEE"/>
          <path d="M67.8738 120.512H67.0938V122.362C67.101 122.823 67.1751 123.282 67.3138 123.722L68.0538 123.482C67.9341 123.121 67.8733 122.742 67.8738 122.362V120.512ZM113.744 88.1319H114.514V86.2619C114.515 85.8069 114.444 85.3547 114.304 84.9219L113.564 85.1519C113.675 85.5113 113.733 85.8855 113.734 86.2619L113.744 88.1319Z" fill="#9CCCEE"/>
          <path d="M114.544 109.999L113.764 110L113.767 113.12L114.547 113.119L114.544 109.999Z" fill="#9CCCEE"/>
          <path d="M71.5853 126.059C70.9672 126.058 70.3592 125.903 69.8153 125.609L69.4453 126.289C70.1015 126.647 70.8378 126.833 71.5853 126.829H72.6453V126.059H71.5853Z" fill="#9CCCEE"/>
          <path d="M114.567 116.217L113.787 116.219L113.795 119.339L114.575 119.337L114.567 116.217Z" fill="#9CCCEE"/>
          <path d="M114.546 103.732L113.766 103.734L113.774 106.854L114.554 106.852L114.546 103.732Z" fill="#9CCCEE"/>
          <path d="M114.522 91.248L113.742 91.25L113.75 94.37L114.53 94.3679L114.522 91.248Z" fill="#9CCCEE"/>
          <path d="M113.754 100.622L114.534 100.612L114.524 97.4922L113.754 97.5022V100.622Z" fill="#9CCCEE"/>
          <path d="M97.6239 126.025L94.5039 126.031L94.5054 126.811L97.6254 126.805L97.6239 126.025Z" fill="#9CCCEE"/>
          <path d="M71.4746 81.8459V82.2259L71.5246 82.6159H74.6446V81.8359L71.4746 81.8459Z" fill="#9CCCEE"/>
          <path d="M105.858 81.7887L102.738 81.7969L102.74 82.5769L105.86 82.5687L105.858 81.7887Z" fill="#9CCCEE"/>
          <path d="M103.864 126.01L100.744 126.016L100.746 126.796L103.866 126.79L103.864 126.01Z" fill="#9CCCEE"/>
          <path d="M99.5985 81.7987L96.4785 81.8047L96.48 82.5847L99.6 82.5787L99.5985 81.7987Z" fill="#9CCCEE"/>
          <path d="M108.975 81.7813V82.5613H109.975C110.604 82.5568 111.224 82.7152 111.775 83.0213L112.145 82.3413C111.481 81.9721 110.734 81.7793 109.975 81.7813H108.975ZM112.645 124.961L113.185 125.521C114.007 124.732 114.497 123.659 114.555 122.521H113.775C113.716 123.447 113.313 124.318 112.645 124.961Z" fill="#9CCCEE"/>
          <path d="M106.984 126.01L106.994 126.78H110.104L110.114 126.39L110.084 126L106.984 126.01Z" fill="#9CCCEE"/>
          <path d="M93.3583 81.8143L90.2383 81.8203L90.2398 82.6003L93.3598 82.5943L93.3583 81.8143Z" fill="#9CCCEE"/>
          <path d="M80.8798 81.8378L77.7598 81.8438L77.7613 82.6238L80.8813 82.6178L80.8798 81.8378Z" fill="#9CCCEE"/>
          <path d="M78.9032 126.049L75.7832 126.055L75.7847 126.835L78.9047 126.829L78.9032 126.049Z" fill="#9CCCEE"/>
          <path d="M85.1434 126.049L82.0234 126.055L82.0249 126.835L85.1449 126.829L85.1434 126.049Z" fill="#9CCCEE"/>
          <path d="M91.3739 126.034L88.2539 126.039L88.2552 126.809L91.3752 126.804L91.3739 126.034Z" fill="#9CCCEE"/>
          <path d="M87.1278 81.82L84.0078 81.8281L84.0099 82.6081L87.1298 82.5999L87.1278 81.82Z" fill="#9CCCEE"/>
          <path d="M93.1037 91.1094H88.5137V114.419H93.1037V91.1094Z" fill="#AAB6C2"/>
          <path d="M102.455 100.484H79.1445V105.074H102.455V100.484Z" fill="#AAB6C2"/>
          <path d="M166.195 106.864H166.975L166.965 103.734L166.195 103.744V106.864Z" fill="#9CCCEE"/>
          <path d="M124.015 126.059C123.397 126.058 122.789 125.903 122.245 125.609L121.865 126.289C122.525 126.647 123.265 126.832 124.015 126.829H125.095V126.059H124.015Z" fill="#9CCCEE"/>
          <path d="M133.309 81.8378L130.189 81.8438L130.191 82.6238L133.311 82.6178L133.309 81.8378Z" fill="#9CCCEE"/>
          <path d="M123.904 81.8459V82.2259L123.954 82.6159H127.074V81.8359L123.904 81.8459Z" fill="#9CCCEE"/>
          <path d="M166.975 109.991L166.195 109.992L166.199 113.112L166.979 113.111L166.975 109.991Z" fill="#9CCCEE"/>
          <path d="M166.997 116.217L166.217 116.219L166.225 119.339L167.005 119.337L166.997 116.217Z" fill="#9CCCEE"/>
          <path d="M131.333 126.049L128.213 126.055L128.214 126.835L131.334 126.829L131.333 126.049Z" fill="#9CCCEE"/>
          <path d="M120.268 95.545L119.488 95.5469L119.496 98.6669L120.276 98.665L120.268 95.545Z" fill="#9CCCEE"/>
          <path d="M120.249 89.3115L119.469 89.3125L119.473 92.4325L120.253 92.4315L120.249 89.3115Z" fill="#9CCCEE"/>
          <path d="M120.284 101.787L119.504 101.789L119.512 104.909L120.292 104.907L120.284 101.787Z" fill="#9CCCEE"/>
          <path d="M120.3 114.257L119.52 114.258L119.523 117.378L120.303 117.377L120.3 114.257Z" fill="#9CCCEE"/>
          <path d="M120.305 120.516H119.525V122.366C119.527 122.828 119.601 123.287 119.745 123.726L120.475 123.486C120.363 123.123 120.306 122.746 120.305 122.366V120.516ZM119.475 86.1659H120.255C120.287 85.2144 120.684 84.3118 121.365 83.6459L120.825 83.0859C119.993 83.899 119.509 85.0032 119.475 86.1659Z" fill="#9CCCEE"/>
          <path d="M120.286 108.021L119.506 108.023L119.514 111.143L120.294 111.141L120.286 108.021Z" fill="#9CCCEE"/>
          <path d="M166.184 100.622L166.964 100.612L166.954 97.4922L166.184 97.5022V100.622Z" fill="#9CCCEE"/>
          <path d="M158.27 81.8009L155.15 81.8047L155.151 82.5847L158.271 82.5809L158.27 81.8009Z" fill="#9CCCEE"/>
          <path d="M152.026 81.8071L148.906 81.8125L148.908 82.5925L152.028 82.5871L152.026 81.8071Z" fill="#9CCCEE"/>
          <path d="M156.294 126.01L153.174 126.016L153.175 126.796L156.295 126.79L156.294 126.01Z" fill="#9CCCEE"/>
          <path d="M159.414 126.01L159.424 126.78H162.534L162.544 126.39L162.514 126L159.414 126.01Z" fill="#9CCCEE"/>
          <path d="M166.952 91.248L166.172 91.25L166.18 94.37L166.96 94.3679L166.952 91.248Z" fill="#9CCCEE"/>
          <path d="M165.084 124.961L165.624 125.521C166.443 124.73 166.932 123.658 166.994 122.521H166.214C166.156 123.447 165.753 124.318 165.084 124.961ZM161.404 81.7813V82.5613H162.454C163.081 82.5571 163.697 82.7156 164.244 83.0213L164.624 82.3413C163.961 81.9721 163.214 81.7793 162.454 81.7813H161.404Z" fill="#9CCCEE"/>
          <path d="M137.573 126.049L134.453 126.055L134.455 126.835L137.575 126.829L137.573 126.049Z" fill="#9CCCEE"/>
          <path d="M139.55 81.8221L136.43 81.8281L136.431 82.6081L139.551 82.6021L139.55 81.8221Z" fill="#9CCCEE"/>
          <path d="M166.164 88.1319H166.944V86.2619C166.945 85.8069 166.874 85.3547 166.734 84.9219L165.994 85.1519C166.106 85.5113 166.163 85.8855 166.164 86.2619V88.1319Z" fill="#9CCCEE"/>
          <path d="M143.813 126.033L140.693 126.039L140.695 126.809L143.815 126.803L143.813 126.033Z" fill="#9CCCEE"/>
          <path d="M150.054 126.025L146.934 126.031L146.935 126.811L150.055 126.805L150.054 126.025Z" fill="#9CCCEE"/>
          <path d="M145.788 81.8065L142.668 81.8125L142.669 82.5925L145.789 82.5865L145.788 81.8065Z" fill="#9CCCEE"/>
          <path d="M145.535 91.1094H140.945V114.419H145.535V91.1094Z" fill="#AAB6C2"/>
          <path d="M154.884 100.484H131.574V105.074H154.884V100.484Z" fill="#AAB6C2"/>
          <path d="M41.3439 91.1094H36.7539V114.419H41.3439V91.1094Z" fill="#AAB6C2"/>
          <path d="M50.31 100H27V104.59H50.31V100Z" fill="#AAB6C2"/>
          <path d="M47 26H41V59H47V26Z" fill="#AAB6C2"/>
          <path d="M60 39H27V45H60V39Z" fill="#AAB6C2"/>
        </g>
      </svg>
      <h3>No discrepancies yet...</h3>
      <span>After reviewing the candidate's resume if you find any gaps, mismatches, or concerns, add them as discrepancies here. These discrepancies will be documented and shared with the respective client for further action.</span>
    </div>
  )
}

export default EmptyDiscrepancyList