// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Notification container */
.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  max-width: 300px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Show notification */
.notification.show {
  display: block;
  opacity: 1;
}

/* Notification title */
.notification-title {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Close button */
.notification-close {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Loading spinner */
.spinner {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/notification.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,UAAU;EACV,oCAAoC;AACtC;;AAEA,sBAAsB;AACtB;EACE,cAAc;EACd,UAAU;AACZ;;AAEA,uBAAuB;AACvB;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA,iBAAiB;AACjB;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA,oBAAoB;AACpB;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB","sourcesContent":["/* Notification container */\n.notification {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: #333;\n  color: white;\n  padding: 15px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  font-size: 14px;\n  max-width: 300px;\n  display: none;\n  opacity: 0;\n  transition: opacity 0.3s ease-in-out;\n}\n\n/* Show notification */\n.notification.show {\n  display: block;\n  opacity: 1;\n}\n\n/* Notification title */\n.notification-title {\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n/* Close button */\n.notification-close {\n  position: absolute;\n  top: 5px;\n  right: 10px;\n  background: none;\n  border: none;\n  color: white;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n/* Loading spinner */\n.spinner {\n  text-align: center;\n  padding: 20px;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
