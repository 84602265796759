import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux';
import store from './redux/store'


function App() {

  const AppRouter = lazy(() => import('../src/routes/Routeindex'))
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>loading...</div>}>
          <AppRouter />          
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
