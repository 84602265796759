// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0px;
  padding: 0px;
}

body {
  background: #f6f6f6;
}

input,
select {
  background: transparent;
}

/* input:disabled,
select:disabled,
input:read-only,
select:read-only {
  background: #f2f2f2;
  color: #898989;
} */

input:focus,
select:focus {
  outline: none;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #edecec3f;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0885dd;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #8bbdff;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
    display: none;
}

input[type="password"]::-webkit-contacts-auto-fill-button:focus,
input[type="password"]::-webkit-credentials-auto-fill-button:focus {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;;;;;;GAMG;;AAEH;;EAEE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":["* {\n  margin: 0px;\n  padding: 0px;\n}\n\nbody {\n  background: #f6f6f6;\n}\n\ninput,\nselect {\n  background: transparent;\n}\n\n/* input:disabled,\nselect:disabled,\ninput:read-only,\nselect:read-only {\n  background: #f2f2f2;\n  color: #898989;\n} */\n\ninput:focus,\nselect:focus {\n  outline: none;\n}\n\n*::-webkit-scrollbar {\n  width: 10px;\n}\n\n*::-webkit-scrollbar-track {\n  background-color: #edecec3f;\n  border-radius: 10px;\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: #0885dd;\n  border-radius: 10px;\n}\n\n*::-webkit-scrollbar-thumb:hover {\n  background: #8bbdff;\n}\n\ninput[type=\"password\"]::-ms-reveal,\ninput[type=\"password\"]::-ms-clear {\n    display: none;\n}\n\ninput[type=\"password\"]::-webkit-contacts-auto-fill-button,\ninput[type=\"password\"]::-webkit-credentials-auto-fill-button {\n    display: none;\n}\n\ninput[type=\"password\"]::-webkit-contacts-auto-fill-button:focus,\ninput[type=\"password\"]::-webkit-credentials-auto-fill-button:focus {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
