// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DiscrepancyList {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}
.DiscrepancyList .Discrepancy {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem;
  border: 0.0625rem solid var(--Stroke);
  border-radius: 0.25rem;
  cursor: pointer;
}
.DiscrepancyList .Discrepancy:hover {
  background: var(--Pending);
}
.DiscrepancyList .Discrepancy span {
  font-size: 0.75rem;
  color: var(--TextColor);
  width: 12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DiscrepancyList .Discrepancy .CheckName {
  font-weight: 700;
  width: 8rem;
}
.DiscrepancyList .Discrepancy .Comments {
  flex: 1 1;
}
.DiscrepancyList .Discrepancy svg {
  min-width: 1.5rem;
}
.DiscrepancyList .Discrepancy svg path {
  fill: var(--AccentColor);
}
.DiscrepancyList .Discrepancy .RefreshChat {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.DiscrepancyList .Discrepancy .RefreshChat:hover {
  transform: rotate(180deg);
}
.DiscrepancyList .Selected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}
.DiscrepancyList .Selected span {
  font-size: 0.625rem;
}
.DiscrepancyList .Selected .CheckName {
  font-size: 0.75rem;
  width: 100%;
}
.DiscrepancyList .Closed {
  background: var(--Invitation);
}`, "",{"version":3,"sources":["webpack://./src/Components/AdminCvAnalysis/DiscrepancyList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,qCAAA;EACA,sBAAA;EACA,eAAA;AAEJ;AADI;EACE,0BAAA;AAGN;AADI;EACE,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAGN;AADI;EACE,gBAAA;EACA,WAAA;AAGN;AADI;EACE,SAAA;AAGN;AADI;EACE,iBAAA;AAGN;AAFM;EACE,wBAAA;AAIR;AADI;EACE,eAAA;EACA,gCAAA;AAGN;AAFM;EACE,yBAAA;AAIR;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AADI;EACE,kBAAA;EACA,WAAA;AAGN;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".DiscrepancyList{\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n  width: 100%;\n  .Discrepancy{\n    display: flex;\n    align-items: center;\n    gap: 1.5rem;\n    padding: 0.5rem;\n    border: 0.0625rem solid var(--Stroke);\n    border-radius: 0.25rem;\n    cursor: pointer;\n    &:hover{\n      background: var(--Pending);\n    }\n    span{\n      font-size: 0.75rem;\n      color: var(--TextColor);\n      width: 12rem;\n      overflow: hidden;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n    }\n    .CheckName{\n      font-weight: 700;\n      width: 8rem;\n    }\n    .Comments{\n      flex: 1;\n    }\n    svg{\n      min-width: 1.5rem;\n      path{\n        fill: var(--AccentColor);\n      }\n    }\n    .RefreshChat{\n      cursor: pointer;\n      transition: all 0.3s ease-in-out;\n      &:hover{\n        transform: rotate(180deg);\n      }\n    }\n  }\n  .Selected{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 0.25rem;\n    span{\n      font-size: 0.625rem;\n    }\n    .CheckName{\n      font-size: 0.75rem;\n      width: 100%;\n    }\n  }\n  .Closed{\n    background: var(--Invitation);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
