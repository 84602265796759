import { configureStore } from "@reduxjs/toolkit";
import { reducers as candidateLoginReducer } from "../redux/slice/CandidateLoginSlice";
import { reducers as companyDashboardReducer } from "../redux/slice/CompanyDashboardSlice";
import { reducers as clientDashboardReducer } from "../redux/slice/ClientDashboardSlice";
import { reducers as clientLoginReducer } from "../redux/slice/ClientLoginSlice";
import { reducers as CompanyInsufficiencyReducer } from "../redux/slice/CompanyInsufficiencySlice";

const store = configureStore({
  reducer: {
    // loginuser: loginReducer,
    //candidate
    candidateLogin: candidateLoginReducer.candidateLogin,
    candidateSignature: candidateLoginReducer.candidateSignature,
    candidatePersonalInfo: candidateLoginReducer.candidatePersonalInfo,
    candidateEducationInfo: candidateLoginReducer.candidateEducationInfo,
    candidateEmploymentInfo: candidateLoginReducer.candidateEmploymentInfo,
    candidateEmploymentInfoUpdate:
      candidateLoginReducer.candidateEmploymentInfoUpdate,
    candidateSetPassword: candidateLoginReducer.candidateSetPassword,
    candidateVerification: candidateLoginReducer.candidateVerification,
    candidateResidentInfo: candidateLoginReducer.candidateResidentInfo,
    candidateIdCheck: candidateLoginReducer.candidateIdCheck,
    forgotPassword: candidateLoginReducer.forgotPassword,
    setNewPassword: candidateLoginReducer.setNewPassword,
    allCandidateDetails: candidateLoginReducer.allCandidateDetails,
    addCommunicationPreference:
      candidateLoginReducer.addCommunicationPreference,
    candidateLoginStatusUpdate:
      candidateLoginReducer.candidateLoginStatusUpdate,
    //company
    companyDashboard: companyDashboardReducer.companyDashboard,
    companyDashboardNew: companyDashboardReducer.companyDashboardNew,
    companyDashboardAmount: companyDashboardReducer.companyDashboardAmount,
    companyDashboardPacks: companyDashboardReducer.companyDashboardPacks,
    clientLicenseVerify: clientLoginReducer.clientLicenseVerify,
    clientCreatePassword: clientLoginReducer.clientCreatePassword,
    addClientInfo: clientLoginReducer.clientAddInfo,
    addClientBusinessInfo: clientLoginReducer.addClientBusinessInfo,
    addClientBusinessDetails: clientLoginReducer.addClientBusinessDetails,
    companyPlans: companyDashboardReducer.companyPlans,
    ServiceproviderCompanyRates: companyDashboardReducer.ServiceproviderCompanyRates,
    beginVerification: clientLoginReducer.beginVerification,
    CancelbeginVerification: clientLoginReducer.CancelbeginVerification,
    GetListOfChecks: companyDashboardReducer.GetListOfChecks,
    GetClientInformation: companyDashboardReducer.GetClientInformation,
    createCustomPlan: companyDashboardReducer.createCustomPlan,
    updateClientInformation: companyDashboardReducer.updateClientInformation,
    GetCandidateReportSummary:
      companyDashboardReducer.GetCandidateReportSummary,
    GetCandidateReportDetails:
      companyDashboardReducer.GetCandidateReportDetails,
    clientLogout: clientLoginReducer.clientLogout,
    clientFeedbackSubmit: companyDashboardReducer.clientFeedbackSubmit,
    GetBillingDetails: companyDashboardReducer.GetBillingDetails,
    DeletePlan: companyDashboardReducer.DeletePlan,
    ClientCandidateSearch: clientDashboardReducer.ClientCandidateSearch,
    GetStatsClient: clientDashboardReducer.GetStatsClient,
    GetDailyStatsClient: clientDashboardReducer.GetDailyStatsClient,
    GetAmountSpentClient: clientDashboardReducer.GetAmountSpentClient,
    GetDashboardInteractionClient:
      clientDashboardReducer.GetDashboardInteractionClient,
    GetPacksListClient: clientDashboardReducer.GetPacksListClient,
    companyDashboardList: companyDashboardReducer.companyDashboardList,
    searchCandidate: companyDashboardReducer.searchCandidate,
    getUserCheck: companyDashboardReducer.getUserCheck,
    updateChecks: companyDashboardReducer.updateChecks,
    // tasks: taskReducers
    adminInsufficientCandidateList:
      CompanyInsufficiencyReducer.adminInsufficientCandidateList,
    vendorFetchInsufficientCheckwise:
      CompanyInsufficiencyReducer.vendorFetchInsufficientCheckwise,
    addCompanyInsufficientEducationInfo:
      CompanyInsufficiencyReducer.addCompanyInsufficientEducationInfo,
    addCompanyInsufficientEmpInfo:
      CompanyInsufficiencyReducer.addCompanyInsufficientEmpInfo,
    vendorAddInsufficientOtherCheck:
      CompanyInsufficiencyReducer.vendorAddInsufficientOtherCheck,
    vendorAddReferenceInsufficientChecks:
      CompanyInsufficiencyReducer.vendorAddReferenceInsufficientChecks,
  },
});

export default store;
