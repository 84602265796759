import React, { useState } from 'react'
import './DiscrepancyList.scss'

const DiscrepancyList = ({discrepancyData, ShowChat, handleChatClick, Trigger, setTrigger}) => {

  const renderFull = (data, idx) => {
    return (
      <div className={`Discrepancy ${data.is_discrepancy_closed === 1 ? 'Closed' : ''}`} key={idx} onClick={() => {handleChatClick(data)}}>
        <span className='CheckName' title={data?.display_name}>{data?.display_name}</span>
        <span title={`${data?.company_college_name} (${data?.start_month}-${data?.start_year})`}>{data?.company_college_name} ({data?.start_month}-{data?.start_year})</span>
        <span className='Comments'>{data?.message?.[0]?.message}</span>
        {data.is_discrepancy_closed === 1 ?
          <div title='Discrepancy Closed' style={{height: '1rem', width: '1rem', cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 25" fill="none">
              <path d="M12 2.5C6.5 2.5 2 7 2 12.5C2 14.8 2.8 17 4.3 18.8L2.3 20.8C1.9 21.2 1.9 21.8 2.3 22.2C2.5 22.4 2.7 22.5 3 22.5H12C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5ZM16.7 10.8L11.3 16.2C10.9 16.6 10.3 16.6 9.9 16.2L7.3 13.6C6.9 13.2 6.9 12.6 7.3 12.2C7.7 11.8 8.3 11.8 8.7 12.2L10.6 14.1L15.3 9.4C15.7 9 16.3 9 16.7 9.4C17.1 9.7 17.1 10.4 16.7 10.8Z" fill="#102C97"/>
            </svg>
          </div>
          :
          <div title='Discrepancy Chat' style={{height: '1rem', width: '1rem', cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 25" fill="none">
              <path d="M20.1673 7.83594H19.0007V4.33594C19.0007 3.40768 18.6319 2.51744 17.9755 1.86106C17.3191 1.20469 16.4289 0.835938 15.5007 0.835938H3.83398C2.90573 0.835938 2.01549 1.20469 1.35911 1.86106C0.702733 2.51744 0.333984 3.40768 0.333984 4.33594V18.3359C0.335134 18.5663 0.404437 18.7911 0.533159 18.9821C0.661881 19.1731 0.844262 19.3217 1.05732 19.4093C1.19578 19.4746 1.34761 19.5065 1.50065 19.5026C1.65419 19.5035 1.8064 19.4741 1.94854 19.416C2.09069 19.3579 2.21997 19.2724 2.32898 19.1643L5.60732 15.8743H7.33398V17.5543C7.33398 18.4825 7.70273 19.3728 8.35911 20.0291C9.01549 20.6855 9.90573 21.0543 10.834 21.0543H18.9073L21.6723 23.8309C21.7813 23.9391 21.9106 24.0246 22.0528 24.0827C22.1949 24.1407 22.3471 24.1702 22.5007 24.1693C22.6537 24.1732 22.8055 24.1412 22.944 24.0759C23.157 23.9884 23.3394 23.8398 23.4681 23.6488C23.5969 23.4578 23.6662 23.2329 23.6673 23.0026V11.3359C23.6673 10.4077 23.2986 9.51744 22.6422 8.86106C21.9858 8.20469 21.0956 7.83594 20.1673 7.83594ZM7.33398 11.3359V13.5409H5.12898C4.97544 13.54 4.82324 13.5695 4.68109 13.6275C4.53895 13.6856 4.40966 13.7711 4.30065 13.8793L2.66732 15.5243V4.33594C2.66732 4.02652 2.79023 3.72977 3.00903 3.51098C3.22782 3.29219 3.52456 3.16927 3.83398 3.16927H15.5007C15.8101 3.16927 16.1068 3.29219 16.3256 3.51098C16.5444 3.72977 16.6673 4.02652 16.6673 4.33594V7.83594H10.834C9.90573 7.83594 9.01549 8.20469 8.35911 8.86106C7.70273 9.51744 7.33398 10.4077 7.33398 11.3359ZM21.334 20.1909L20.1673 19.0243C20.0593 18.914 19.9304 18.8263 19.7882 18.7663C19.646 18.7062 19.4933 18.6749 19.339 18.6743H10.834C10.5246 18.6743 10.2278 18.5514 10.009 18.3326C9.79023 18.1138 9.66732 17.817 9.66732 17.5076V11.3359C9.66732 11.0265 9.79023 10.7298 10.009 10.511C10.2278 10.2922 10.5246 10.1693 10.834 10.1693H20.1673C20.4767 10.1693 20.7735 10.2922 20.9923 10.511C21.2111 10.7298 21.334 11.0265 21.334 11.3359V20.1909Z" fill="#102C97"/>
            </svg>
          </div>
        }
      </div>
    )
  }
  const renderSelection = (data, idx) => {
    return (
    <div className={`Discrepancy ${data.is_discrepancy_closed === 1 ? 'Closed' : ''}`} key={idx} onClick={() => {handleChatClick(data)}} style={{gap: '0.5rem'}}>
      <div className='Selected'>
        <span className='CheckName' title={data?.display_name}>{data?.display_name}</span>
        <span title={`${data?.company_college_name} (${data?.start_month}-${data?.start_year})`}>{data?.company_college_name} ({data?.start_month}-{data?.start_year})</span>
      </div>
      {data.is_discrepancy_closed === 1 ?
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 25" fill="none">
          <path d="M12 2.5C6.5 2.5 2 7 2 12.5C2 14.8 2.8 17 4.3 18.8L2.3 20.8C1.9 21.2 1.9 21.8 2.3 22.2C2.5 22.4 2.7 22.5 3 22.5H12C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5ZM16.7 10.8L11.3 16.2C10.9 16.6 10.3 16.6 9.9 16.2L7.3 13.6C6.9 13.2 6.9 12.6 7.3 12.2C7.7 11.8 8.3 11.8 8.7 12.2L10.6 14.1L15.3 9.4C15.7 9 16.3 9 16.7 9.4C17.1 9.7 17.1 10.4 16.7 10.8Z" fill="#102C97"/>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" className='RefreshChat' onClick={() => {setTrigger(!Trigger)}}>
          <g clipPath="url(#clip0_7490_18966)">
            <path d="M2.02489 8.77512C2.53114 7.72512 3.24364 6.78762 4.04989 6.00012C4.85614 5.21262 5.77489 4.59387 6.74989 4.14387C7.72489 3.69387 8.73739 3.45012 9.73114 3.35637C10.7249 3.28137 11.7186 3.35637 12.6561 3.61887C13.5936 3.86262 14.4374 4.27512 15.1874 4.80012C15.6186 5.10012 16.0124 5.43762 16.3686 5.81262L13.3686 7.78137L20.8499 9.30012L22.3686 1.81887L19.8374 3.48762C19.4436 3.13137 19.0311 2.79387 18.5999 2.49387C16.5186 1.03137 13.9311 0.243869 11.1374 0.431369C4.93114 0.881369 0.243638 6.07512 0.393638 12.3001C0.412388 12.9564 0.487388 13.6126 0.599888 14.2501C0.618638 14.3626 0.768638 14.4189 0.862388 14.3626C0.918638 14.3064 0.956138 14.2501 0.937388 14.1939C0.824888 12.2814 1.21864 10.3876 2.02489 8.77512ZM23.1186 9.61887C23.0624 9.65637 23.0436 9.71262 23.0436 9.76887C23.1561 11.7001 22.7811 13.6126 21.9749 15.2064C21.4686 16.2564 20.7561 17.1939 19.9499 17.9814C19.1436 18.7689 18.2249 19.3876 17.2499 19.8376C16.2749 20.2876 15.2624 20.5314 14.2686 20.6251C13.2749 20.7001 12.2811 20.6251 11.3436 20.3626C10.4061 20.1189 9.54364 19.7064 8.79364 19.1814C8.36239 18.8814 7.98739 18.5439 7.63114 18.1876L10.6124 16.2189L3.13114 14.7001L1.61239 22.1814L4.14364 20.5126C4.51864 20.8689 4.93114 21.1876 5.36239 21.4876C7.46239 22.9689 10.0874 23.7564 12.8999 23.5501C19.1249 23.0814 23.7936 17.8689 23.5874 11.6251C23.5686 10.9689 23.4936 10.3501 23.3624 9.73137C23.3624 9.61887 23.2311 9.56262 23.1186 9.61887Z" fill="#2A2D33"/>
          </g>
          <defs>
            <clipPath id="clip0_7490_18966">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      }
    </div>
    )
  }
  return (
    <div className='DiscrepancyList'>
      {(discrepancyData && discrepancyData?.length > 0) &&
        (ShowChat ?
          discrepancyData.map((discrepancy, index) => (
            renderSelection(discrepancy, index)
          ))
          :
          discrepancyData.map((discrepancy, index) => (
            renderFull(discrepancy, index)
          ))
        )
      }
    </div>
  )
}

export default DiscrepancyList